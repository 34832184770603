import React, { useState, useEffect } from 'react';
import { UploadsFiles } from "../uploads-files/UploadsFiles";
import './Upload.scss';
import * as XLSX from "@sheet/core";
import { Modal, Button, Switch, Typography  } from 'antd';
import {InfoCircleOutlined, SearchOutlined} from '@ant-design/icons';
import ReactGA from 'react-ga';
const { Title } = Typography;

export const Upload: React.FC = () => {

    const [show, setShow] = useState({ visible: false });
    const [isFull, setIsFull] = useState(false);
    const [byId, setById] = useState(false);

    const onChange = () => {
        setById(!byId);
    }

    const showModal = () => {
        setShow({
            visible: true,
        });
    };

    const handleOk = () => {
        setShow({
            visible: false,
        });
    };

    const handleCancel = () => {
        setShow({
            visible: false,
        });
    };

    const [nameCols] = useState(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
        'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
        'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
        'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ',
        'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR',
        'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ'])
    const [newFile, setNewFile] = useState();
    const [oldFile, setOldFile] = useState();
    const [sheet, setSheet] = useState();
    const [oldSheet, setOldSheet] = useState();
    const date = new Date();
    const month = ['January', 'February', 'March', 'April', 'May',
        'June', 'July', 'August', 'September', 'October', 'November',
        'December'];

    const completeDate = `${date.getHours()}-${date.getMinutes()}-${date.getDate()}-${month[date.getMonth()]}-${date.getFullYear()}`;

    const createWorkBook = (state: any, setState: any) => {
        let reader = new FileReader()
        reader.onload = function (event: any) {
            let data = new Uint8Array(event.target.result);
            let workbook = XLSX.read(data, {type: "array", cellStyles: true})
            const ws: XLSX.WorkSheet = workbook.Sheets[workbook.SheetNames[0]];
            setState(ws);
            //console.log(ws)
            if (ws.A1 === undefined) {
                alert('Les tableaux doivent commencer en A1. Impossible de comparer les fichiers, vérifier que vous répondre au conditions dans le point info.');
                window.location.reload(true);
            }
        }
        reader.readAsArrayBuffer(state)
    };

    const parseString = (array: any) => {
        let newArray = []
        for (let i = 0; i < array.length; i++) {
            newArray.push(array[i].join(''));
        }
        return newArray
    }

    const utilKeys = (allKeys: any) => {
        let result = [];
        for (let i = 0; i < allKeys.length; i++) {
            if (allKeys[i].substr(0,1) === 'A') {
                result.push(allKeys[i])
            }
        }
        return result
    }

    const checkHeader = (newSheet: any, oldSheet: any) => {
        let oldSheetJson = parseString(XLSX.utils.sheet_to_json(oldSheet, {header: 1}));
        let newSheetJson = parseString(XLSX.utils.sheet_to_json(newSheet, {header: 1}));
        return newSheetJson[0].split(' ').join('').toLowerCase() === oldSheetJson[0].split(' ').join('').toLowerCase();
    };


    const recupRow = (array: any) => {
        let result = [];
        for (let i = 0; i < array.length; i++){
            result.push(array[i][0])
        }
        return result
    };


    const isInclude = (arrayKeys: any, newObjectArray: any, oldObjectArray: any) => {
        let result = [];
        for( let y = 0; y < arrayKeys.length; y++) {
            for (let i = 0; i < arrayKeys.length; i++) {
                if (oldObjectArray.hasOwnProperty(arrayKeys[i])) {
                    if (newObjectArray[arrayKeys[y]].v === oldObjectArray[arrayKeys[i]].v) {
                        result.push({new: arrayKeys[y].substr(1,4), old: arrayKeys[i].substr(1,4)})
                    }
                }
            }
        }
        return result
    };

    const compareSheetById = (oldSheet: any, newSheet: any) => {
        let keys = utilKeys(Object.keys(sheet))
        let rowsInclude = isInclude(keys, sheet, oldSheet)
        let refDoubleCols =`${oldSheet["!ref"].slice(3).split('')[0]}${oldSheet["!ref"].slice(3).split('')[1]}`;
        let refCol = nameCols.includes(refDoubleCols) ? nameCols.indexOf(refDoubleCols) : nameCols.indexOf(oldSheet["!ref"].slice(3).split('')[0]);
        let newRow = recupRow(XLSX.utils.sheet_to_json(newSheet, {header: 1}));
        let oldRow = recupRow(XLSX.utils.sheet_to_json(oldSheet, {header: 1}));

        if (checkHeader(newSheet, oldSheet)) {
            for (let i = 0; i < rowsInclude.length; i++) {
                for (let y = 0; y <= refCol; y++) {
                    let newKey =`${nameCols[y]}${rowsInclude[i].new}`;
                    let oldKey = `${nameCols[y]}${rowsInclude[i].old}`;
                    if (newSheet.hasOwnProperty(newKey)) {
                        if (oldSheet.hasOwnProperty(oldKey)){
                            if (newSheet[newKey].v !== oldSheet[oldKey].v) {
                                for (let x = 0; x <= refCol; x++){
                                    let keyRow =`${nameCols[x]}${rowsInclude[i].new}`;
                                    if (newSheet.hasOwnProperty(keyRow)) {
                                        newSheet[keyRow].s = { bold: 1 };
                                        newSheet[keyRow].s.color = { rgb: "FF0000" };
                                        newSheet[keyRow].s.fgColor = { rgb: 'DADADA' };
                                        newSheet[keyRow].s.bottom = {style: "dashDot"};
                                        newSheet[keyRow].s.bottom.color = { rgb: "FF0000" };
                                        newSheet[keyRow].s.top = {style: "dashDot"};
                                        newSheet[keyRow].s.top.color = { rgb: "FF0000" };
                                    }
                                }
                                newSheet[newKey].s = { bold: 1 };
                                newSheet[newKey].s.color = { rgb: "FF0000" };
                                newSheet[newKey].s.fgColor = { rgb: '800080' };
                                newSheet[newKey].s.bottom = {style: "dashDot"};
                                newSheet[newKey].s.bottom.color = { rgb: "FF0000" };
                                newSheet[newKey].s.top = {style: "dashDot"};
                                newSheet[newKey].s.top.color = { rgb: "FF0000" };
                            }
                        }
                    }
                }
            }
            for (let i = 0; i < newRow.length; i++) {
                if (!oldRow.includes(newRow[i])){
                    let newRow = `A${i +1}`
                    newSheet[newRow].s = { bold: 1 };
                    newSheet[newRow].s.color = { rgb: "FF0000" };
                    newSheet[newRow].s.fgColor = { rgb: 'DADADA' };
                    newSheet[newRow].s.bottom = {style: "dashDot"};
                    newSheet[newRow].s.bottom.color = { rgb: "FF0000" };
                    newSheet[newRow].s.top = {style: "dashDot"};
                    newSheet[newRow].s.top.color = { rgb: "FF0000" };
                }
            }
            return newSheet
        } else {
            alert('Résultat non valable, les entêtes des tableaux ne son pas identique, vérifier que vous répondre au conditions dans le point info.');
            window.location.reload(true);
        }
    };

    const compareSheet = (oldSheet: any, newSheet: any) => {
        let oldSheetJson = parseString(XLSX.utils.sheet_to_json(oldSheet, {header: 1}));
        let newSheetJson = parseString(XLSX.utils.sheet_to_json(newSheet, {header: 1}));
        let refKeys = Object.keys(newSheet);
        let refRows = oldSheet["!rows"].length;
        let refDoubleCols =`${oldSheet["!ref"].slice(3).split('')[0]}${oldSheet["!ref"].slice(3).split('')[1]}`;
        let refCol = nameCols.includes(refDoubleCols) ? nameCols.indexOf(refDoubleCols) : nameCols.indexOf(oldSheet["!ref"].slice(3).split('')[0]);
        let newRow = recupRow(XLSX.utils.sheet_to_json(newSheet, {header: 1}));
        let oldRow = recupRow(XLSX.utils.sheet_to_json(oldSheet, {header: 1}));

        if (checkHeader(newSheet, oldSheet)) {
            const checking = (numRow: number) => {
                numRow--
                if (oldSheetJson.includes(newSheetJson[numRow])) {
                    return oldSheetJson.includes(newSheetJson[numRow])
                } else {
                    return false
                }
            }

            for (let i = 1; i <= refRows; i++) {
                if (!checking(i)) {
                    for (let y = 0; y <= refCol; y++) {
                        let cellsKeys = `${nameCols[y]}${i}`
                        if (refKeys.includes(cellsKeys)) {
                            newSheet[cellsKeys].s = { bold: 1 };
                            newSheet[cellsKeys].s.color = { rgb: "FF0000" };
                            newSheet[cellsKeys].s.fgColor = { rgb: "DADADA" };
                            newSheet[cellsKeys].s.bottom = {style: "dashDot"};
                            newSheet[cellsKeys].s.bottom.color = { rgb: "FF0000" };
                            newSheet[cellsKeys].s.top = {style: "dashDot"};
                            newSheet[cellsKeys].s.top.color = { rgb: "FF0000" };
                        }
                    }
                }
            }
            for (let i = 0; i < newRow.length; i++) {
                if (!oldRow.includes(newRow[i])){
                    let newRow = `A${i +1}`
                    newSheet[newRow].s = { bold: 1 };
                    newSheet[newRow].s.color = { rgb: "FF0000" };
                    newSheet[newRow].s.fgColor = { rgb: 'DADADA' };
                    newSheet[newRow].s.bottom = {style: "dashDot"};
                    newSheet[newRow].s.bottom.color = { rgb: "FF0000" };
                    newSheet[newRow].s.top = {style: "dashDot"};
                    newSheet[newRow].s.top.color = { rgb: "FF0000" };
                }
            }
            return newSheet
        } else {
            alert('Résultat non valable, les entêtes des tableaux ne son pas identique, vérifier que vous répondre au conditions dans le point info.');
            window.location.reload(true);
        }
    };

    const writeSheet = (data: any) => {
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, `${completeDate}.xlsx`);
        XLSX.writeFile(wb, `${completeDate}.xlsx`, { bookType: 'xlsx', cellStyles: true });
        window.location.reload(true);
    };

    useEffect(() => {
        if (newFile && oldFile) {
            createWorkBook(newFile.selectedFiles, setSheet);
            createWorkBook(oldFile.selectedFiles, setOldSheet);
            setIsFull(true);
        }
    }, [newFile, oldFile]);

    const gaEvent = (category: string, action: string) => {
        ReactGA.event({
            category: category,
            action: action
        });
    };

    return (
        <div className='upload-component'>
            <form className="form-upload">
                <Title level={3} style={{color: "white", textAlign: 'right', width: "80%", margin: 0}}>{byId ? 'Comparaison avec id unique' : 'Comparaison sans id unique'}</Title>
                <div style={{display: 'flex', justifyContent: "flex-end", width: "80%"}}>
                    <Switch defaultChecked onChange={onChange} />
                </div>
                <UploadsFiles titleProps={"à comparer"} setState={setNewFile}/>
                <UploadsFiles titleProps={"de référence"} setState={setOldFile}/>
            </form>
            <div className='button-container'>
                <Button size={"large"} icon={<SearchOutlined translate={false} style={{color: "#fff"}} />} disabled={ !isFull } style={isFull ? {backgroundColor: '#FF8900'} : {backgroundColor: 'lightgray'}} className="button-compare" onClick={async () => {
                    if (isFull) {
                        try {
                            if (byId) {
                                writeSheet(compareSheetById(oldSheet, sheet));
                                gaEvent('Used and success', 'The comparison works');
                            } else {
                                writeSheet(compareSheet(oldSheet, sheet));
                                gaEvent('Used and error', 'The comparison works');
                            }
                        } catch (e) {
                            gaEvent('Used', 'The comparison didn\'t work');
                            alert('Impossible de comparer les fichiers, vérifier que vous répondre au conditions dans le point info.');
                            window.location.reload(true);
                        }
                    }
                }}>
                    <p>Comparer</p>
                </Button>
                <Button size={"large"} className="button-info" icon={<InfoCircleOutlined translate={false} />} type="primary" onClick={showModal}>
                    Infos
                </Button>
                <Modal
                    title="Informations"
                    visible={show.visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                >
                    { byId ? <TermOfUseId /> : <TermOfUse /> }
                </Modal>
            </div>

        </div>

    );
};

export const TermOfUseId: React.FC = () => (
    <div>
        <p>- Cette application compare deux fichiers Excel et vous retourne un nouveau fichier avec les différences relever. </p>
        <p>- Extension de fichier accepter '.xlsx'.</p>
        <p>- Les fichiers doivent être de la version 2020 de Excel</p>
        <p>- Les feuilles à comparer doivent être en première place dans le classeur aussi bien pour le fichier a comparer que celui de référence.</p>
        <p>- Les tableaux ne doivent pas excéder 52 colonnes et 9999 lignes</p>
        <p>- Les entêtes des tableaux doivent être identique</p>
        <p>- Les tableaux doivent commencer en A1</p>
        <p>- Les id doivent être unique et stocker dans la colonne A</p>
        <p>- Une fois la comparaison lancer un téléchargement d’un classeur Excel contenant le résultat de comparaison se lance automatiquement.</p>
        <p>- Les lignes modifier seront notifier part des pointiller rouge et un background gris et la cellule qui en est la cause aura un background violet</p>
        <p>- Une nouvelle ligne aura juste l'id notifier part des pointiller rouge et un background gris</p>
    </div>
);

export const TermOfUse: React.FC = () => (
    <div>
        <p>- Cette application compare deux fichiers Excel et vous retourne un nouveau fichier avec les différences relever. </p>
        <p>- Extension de fichier accepter '.xlsx'.</p>
        <p>- Les fichiers doivent être de la version 2020 de Excel</p>
        <p>- Les feuilles à comparer doivent être en première place dans le classeur aussi bien pour le fichier a comparer que celui de référence.</p>
        <p>- Les entêtes des tableaux doivent être identique</p>
        <p>- Les tableaux doivent commencer en A1</p>
        <p>- Les tableaux ne doivent pas excéder 52 colonnes.</p>
        <p>- Une fois la comparaison lancer un téléchargement d’un classeur Excel contenant le résultat de comparaison se lance automatiquement.</p>
        <p>- Les différences relever seront notifier part des pointiller rouge et un background gris.</p>
        <img src={require('../../assets/result-image.png')} alt="result"/>
    </div>
);