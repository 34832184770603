import React from 'react';
import './HeaderHome.scss';
import { Row, Col } from 'antd';
import { Typography } from 'antd';

const { Title } = Typography;

export const HeaderHome: React.FC = () => (
    <Row className="header-home">
        <Col span={12} className="header-logo">
                <img className="logo-altyor" src={require('../../assets/logo_altyor.png')} alt="Logo-Altyor"/>
        </Col>
        <Col span={12} className="header-title">
            <Title className="title-container">Supply Ch<em>ain</em></Title>
        </Col>
    </Row>
);
