import React from 'react';
import { HeaderHome } from '../../components/header-home/HeaderHome';
import { Upload } from '../../components/upload/Upload';
import './Home.scss';

export const Home: React.FC = () =>  (
    <div className="home-page">
        <div className="background"></div>
        <div className="dashboard">
            <HeaderHome />
            <Upload />
        </div>
    </div>
);
