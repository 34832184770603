import React from 'react';
import { Upload } from 'antd';
import './UploadsFiles.scss';
const { Dragger } = Upload;

type Props = {
    setState: Function,
    titleProps: string
}

export const UploadsFiles: React.FC<Props> = ({ setState, titleProps }) => {

    const handleUpload = (file : File, fileList : any) => {
        setState({ 'selectedFiles': file });
        return false;
    }

    return (
        <div className="input-file-box">
            <Dragger beforeUpload={handleUpload} className="drag" multiple={false} accept={'.xlsx'}>
                <p className="ant-upload-drag-icon">
                    <img className="excel-logo" src={require('../../assets/Logo_Excel.svg')} alt="Logo-excel"/>
                </p>
                <span className="description-container">
                    <p className="ant-upload-text">{`Cliquer ou glisser déposer vôtre fichier ${titleProps}.`}</p>
                </span>
            </Dragger>
        </div>
    );
};
