import  * as React from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {ICredential} from './App';

import './App.css';
interface ICredentialsCallback {
  callback : (props :ICredential) => void,
  fetchLogin : () => void,
  existingToken : string
}

function useQuery() : URLSearchParams{
  return new URLSearchParams(useLocation().search);
}

export function Token(myProps: ICredentialsCallback) {
  let newToken = useQuery().get("token");

    console.log("newtoken is : " + newToken + "old token is " + myProps.existingToken);
    if (newToken != null) {
      console.log("token not null")
      myProps.callback({token : newToken})
    } else {
      myProps.callback({token : myProps.existingToken})
    }
    myProps.fetchLogin()
    return (<Redirect to="/home" /> )
}
